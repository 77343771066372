<template>
  <div>Please wait...</div>
</template>

<script>
export default {
  name: "auth",
  async beforeCreate() {
    try {
      let { t, u } = this.$route.query;

      if (process.env.ENV !== "production" && this.$route.query?.demo) {
        console.log("demo", this.$route.query?.demo);
        try {
          let { token } = await this.$axios.post("/api/v1/auth/demo", {
            user_id: this.$route.query?.demo,
            business: true,
          });

          t = token;
          u = this.$route.query?.demo;
        } catch (e) {
          console.error(e);
        }
      }

      this.$store.commit("SET_TOKEN", t);
      this.$store.commit("SET_USER_ID", u);
    } catch (e) {
      this.$log.notify(e);
    }

    this.$router
      .replace({
        name: "welcome",
        params: {
          ...this.$route.params,
          locale: process.env.VUE_APP_DEFAULT_LOCALE,
        },
      })
      .catch(() => {});
  },
};
</script>
